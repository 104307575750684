// src/App.js
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Authenticator } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import Header from './components/Header';
import HomeSection from './components/Home';
import ServicesSection from './components/ServicesSection';
import InstructionalVideosSection from './components/VideoLibrary';
import QRCodeSection from './components/QRCodeSection';
import MembersMenu from './components/MembersMenu';
import Login from './components/Login';
import ProtectedRoute from './components/ProtectedRoute';
import '@aws-amplify/ui-react/styles.css';
import './App.css';
import './components/AuthenticatorOverrides.css';

// Configure Amplify with your aws-exports.js configuration
Amplify.configure(awsExports);

// Define the main application content for public routes
function PublicContent() {
  return (
    <div
      style={{
        width: '100%',
        margin: 0,
        padding: 0,
        display: 'flex',
        flexDirection: 'column', // Stack sections vertically
      }}
    >
      <HomeSection />
      <ServicesSection />
      <InstructionalVideosSection />
      <QRCodeSection />
    </div>
  );
}

function App() {
  return (
    <Authenticator.Provider>
      {' '}
      {/* Wrap with Authenticator.Provider */}
      <Header /> {/* Now Header is within the Authenticator context */}
      <Routes>
        {/* Public Routes */}
        <Route path='/' element={<PublicContent />} />
        {/* Dedicated Login Route */}
        <Route path='/login' element={<Login />} />
        {/* Protected Routes */}
        <Route
          path='/members'
          element={
            <ProtectedRoute>
              <MembersMenu />
            </ProtectedRoute>
          }
        />
        {/* Redirect all unknown routes to home */}
        <Route path='*' element={<Navigate to='/' replace />} />
      </Routes>
    </Authenticator.Provider>
  );
}

export default App;
